import {UDTopCardResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
  userInfo: UDTopCardResVo
};

const Card = ({ userInfo }: CardProps) => {

  return (

    <div className="col-12 col-md-7">
      <div className="card custom-card job-candidate-details profile-card">
        <div className="candidate-bg-shape primary">
        </div>
        <div className="card-body pt-5">

          <div className="mt-4">
            <span className="avatar avatar-xxl avatar-rounded online mb-3"> <img src={`${userInfo.imgPath ? userInfo.imgPath : 'https://spruko.com/demo/xintra/dist/assets/images/faces/11.jpg'}`} alt=""/> </span>
          </div>
          <div className="d-flex gap-2 flex-wrap mb-1">
            <div className="flex-fill">
              <h6 className="mb-1 fw-semibold"><a href="javascript:void(0);"> {userInfo.nickName}
                <i className="ri-check-line text-success fs-16" data-bs-toggle="tooltip" aria-label="Verified candidate" data-bs-original-title="Verified candidate"></i></a>
              </h6>
              <p className="mb-0 text-muted">{userInfo.job}</p>
              <div className="d-flex flex-wrap gap-2 align-items-center fs-12 text-muted">
                <p className="mb-0">
                  <i className="ri-phone-line me-1 text-muted"></i>
                  <span className="fw-medium">{userInfo.phone}</span></p>
              </div>
              <div className="d-flex flex-wrap gap-2 align-items-center fs-12 text-muted">
                <p className="mb-0">
                  <i className="ri-map-pin-line me-1 text-muted"></i>
                  <span className="fw-medium">{userInfo.country}, {userInfo.regionName}, ${userInfo.city}</span>
                </p>
              </div>
              <div className="d-flex fs-14 mt-3 gap-2 flex-wrap">
                <div className="me-3">
                  <p className="mb-1 d-flex align-items-center">
                    <span className="avatar avatar-sm avatar-rounded text-primary p-1 bg-primary-transparent me-2"> <i className="ri-mail-line align-middle fs-15"></i> </span>
                    <span className="">ID : <span className="text-muted fw-normal">{userInfo.userId}</span></span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <span className="avatar avatar-sm avatar-rounded text-primary1 p-1 bg-primary-transparent me-2"> <i className="ri-lock-line align-middle fs-15"></i> </span>
                    <span className="">Last Sigin in : <span className="text-muted fw-normal">{userInfo.loginDt}</span></span>
                  </p>
                </div>
                <div className="me-3">
                  <p className="mb-1 d-flex align-items-center">
                    <span className="avatar avatar-sm avatar-rounded text-primary2 p-1 bg-primary-transparent me-2"> <i className="ri-wifi-fill align-middle fs-15"></i> </span>
                    <span className="">IP : <span className="text-muted fw-normal">{userInfo.ip}</span></span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <span className="avatar avatar-sm avatar-rounded text-primary3 p-1 bg-primary-transparent me-2"> <i className="ri-calendar-check-line align-middle fs-15"></i> </span>
                    <span className="">Sigin up : <span className="text-muted fw-normal">{userInfo.regDt}</span></span>
                  </p>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
};

export default Card;
