import React, { useCallback, useEffect, useState } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {Col, Row, Spinner} from "reactstrap";
import * as XLSX from "xlsx";
import useQueryParams from "@/helpers/hooks";
import DateRangePicker from "./components/DateRangePicker";
import StatisticsButtons from "./components/StatisticsButtons";
import PageContainer from "../../components/Common/PageContainer";
import { formatNumber } from "../Dashboard/utils";

type ApiDataValue = string | number | boolean | null;
type ApiDataItem = Record<string, ApiDataValue>;

type Column<T> = {
  key: keyof T | "rank";
  name: string;
  label: string;
};

type ChartComponentProps<T> = {
  data: T | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  children?: React.ReactNode;
  isLoading?: boolean;
};

type Request = {
  startDt: string;
  endDt: string;
};

type StatisticsBaseProps<T, U extends ApiDataItem> = {
  title: string;
  defaultRange: number;
  errorMessage: string;
  columns?: Column<U>[];
  ChartComponent: React.ComponentType<ChartComponentProps<T>>;
  onRequestData: (req: Request) => Promise<{ data: T }>;
  showTable?: boolean;
  getDataList?: (data: T | null) => U[];
  getDataForExport?: (data: T | null) => U[];
};

const StatisticsBase = <T, U extends ApiDataItem>({
  title,
  defaultRange,
  columns,
  errorMessage,
  ChartComponent,
  onRequestData,
  showTable = true,
  getDataList = (data: T | null) => (data as any).list || [],
  getDataForExport = (data: T | null) => (data as any).list || [],
}: StatisticsBaseProps<T, U>) => {
  const { dateRange } = useQueryParams();
  const [selectedDates, setSelectedDates] = useState(dateRange);
  const [selectedRange, setSelectedRange] = useState(defaultRange);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const handleDateChange = (
    selectedDates: [string, string],
    range?: number,
  ) => {
    setSelectedDates(selectedDates);
    if (range) setSelectedRange(range);
  };

  const mapColumnsToRowData = useCallback(
    (item: U, index: number) => {
      if (!columns) return [];
      return columns?.map((column) =>
        column.key === "rank" ? index + 1 : item[column.key],
      );
    },
    [columns],
  );

  const handleCopy = () => {
    if (!data || !columns) return;
    const header = columns.map((column) => column.name).join("\t");
    const rows = getDataForExport(data)
      .map((item, index) => mapColumnsToRowData(item, index).join("\t"))
      .join("\n");

    navigator.clipboard
      .writeText(`${header}\n${rows}`)
      .then(() => alert("Data copied to clipboard"))
      .catch((err) => console.error("Failed to copy", err));
  };

  const handleExcelDownload = () => {
    if (!data || !columns) return;
    const header = [columns.map((column) => column.name)];
    const rows = getDataForExport(data).map((item, index) =>
      mapColumnsToRowData(item, index),
    );

    const ws = XLSX.utils.aoa_to_sheet([...header, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, title.split(" ").join(""));
    XLSX.writeFile(wb, `${title.split(" ").join("_").toLowerCase()}.xlsx`);
  };

  const handlePdfDownload = () => {
    if (!data || !columns) return;
    const doc = new jsPDF();
    autoTable(doc, {
      head: [columns.map((column) => column?.name)],
      body: getDataForExport(data).map((item, index) =>
        mapColumnsToRowData(item, index),
      ),
    });
    doc.save(`${title.split(" ").join("_").toLowerCase()}.pdf`);
  };

  const requestData = useCallback(
    async ([startDt, endDt]: [string, string]) => {
      if (!startDt && !endDt) return;
      setIsLoading(true);
      try {
        const response = await onRequestData({
          startDt: startDt,
          endDt: endDt,
        });
        setData(response.data);
      } catch (error) {
        console.error(errorMessage, error);
      } finally {
        setIsLoading(false);
      }
    },
    [onRequestData, errorMessage],
  );

  useEffect(() => {
    requestData(selectedDates);
  }, [selectedDates, requestData]);

  const sortDataIfHasDate = (dataList: U[]): U[] => {
    const hasDateField = dataList.length > 0 && "date" in dataList[0];

    if (hasDateField) {
      return [...dataList].sort((b, a) => {
        const dateA = new Date(a.date as string).getTime();
        const dateB = new Date(b.date as string).getTime();
        return dateA - dateB;
      });
    }
    return dataList;
  };

  return (
    <PageContainer
      breadcrumbItems={[
        { title: "Statistics", link: "#" },
        { title: title, link: "#" },
      ]}
      title={title}
    >
      <Row>
        <Col xs={12}>
          <DateRangePicker
            initialDates={selectedDates}
            initialRange={selectedRange}
            onDateChange={handleDateChange}
          />
        </Col>
      </Row>
      {isLoading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <Spinner className="me-2" color="secondary" />
              </div>
          )
      : (<Row>
        <ChartComponent data={data} isFullWidth isLoading={isLoading}>
          <StatisticsButtons
            onHandleCopy={handleCopy}
            onHandleExcelDownload={handleExcelDownload}
            onHandlePdfDownload={handlePdfDownload}
          />
          {showTable && (
            <div className="table-responsive">
              <table className="table table-hover mb-0 text-center align-middle">
                <thead className="table-light">
                  <tr>
                    {columns?.map((column) => (
                      <th key={column.name}>{column.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortDataIfHasDate(getDataList(data)).map((item, index) => (
                    <tr key={index}>
                      {columns?.map((column) => (
                        <td key={column.key.toString()}>
                          {column.key === "rank"
                            ? index + 1
                            : column.key === "activeRate"
                            ? `${item[column.key]}%`
                            : formatNumber(item[column.key])}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </ChartComponent>
      </Row>)}
    </PageContainer>
  );
};

export default StatisticsBase;
