import { request, Types } from "@metamorp/api-base";

// export const apiRequest = async <T extends Types.ApiSuccessResBase<any> | any>(
//     config: Types.ApiReqOptions<any>
// ): Promise<T | null> => {
//     try {
//         const response = await request<any, any>(config);
//         return response;
//     } catch (error) {
//         console.error("API 요청 실패:", config.path, error);
//         return null;
//     }
// };

// 제네릭 타입 수정
export const apiRequest = async <T extends Types.ApiResBase<any>>(
  config: Types.ApiReqOptions<any>,
): Promise<T | null> => {
  try {
    return await request<T>(config);
  } catch (error) {
    console.error("API 요청 실패:", config.path, error);
    return null;
  }
};
