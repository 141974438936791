import * as ApiBase from "@metamorp/api-base";
import {
  AddMaterial,
  GetMaterialList,
  Material,
  MaterialItem,
  UpdateMaterial,
} from "./types";

const updateMaterial = (data: UpdateMaterial) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateMaterial>({
    method: "PUT",
    path: "/api/v1/material/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getMaterialList = (data: GetMaterialList) => {
  type Response = ApiBase.Types.ApiResBase<
    MaterialItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetMaterialList>({
    method: "POST",
    path: "/api/v1/material/list",
    data: data,
  });
};

const addMaterial = (data: AddMaterial) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddMaterial>({
    method: "POST",
    path: "/api/v1/material/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getMaterial = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Material>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/material/${idx}`,
  });
};

const deleteMaterial = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/material/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/material/update`
   *
   * 매터리얼을 수정합니다.
   *
   * Update a material.
   */
  update: updateMaterial,
  /**
   * `POST /api/v1/material/list`
   *
   * 매터리얼 목록을 조회합니다.
   *
   * Retrieve a list of materials.
   */
  getList: getMaterialList,
  /**
   * `POST /api/v1/material/add`
   *
   * 매터리얼을 추가합니다.
   *
   * Add a material.
   */
  add: addMaterial,
  /**
   * `GET /api/v1/material/:idx`
   *
   * 매터리얼을 조회합니다.
   *
   * Retrieve a material.
   */
  get: getMaterial,
  /**
   * `DELETE /api/v1/material/:idx`
   *
   * 매터리얼을 삭제합니다.
   *
   * Delete a material.
   */
  delete: deleteMaterial,
};
