import * as ApiBase from "@metamorp/api-base";
import { ModelRanking } from "./type";
import { DateRange } from "../common/type";

const getRequestModelRanking = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ModelRanking>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/model/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/model/state`
   *
   * @authorization-required
   *
   * Retrieve Requested Models Ranking data.
   */
  get: getRequestModelRanking,
};
