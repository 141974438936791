import * as ApiBase from "@metamorp/api-base";
import { RegistrationStatus } from "./type";
import { DateRange } from "../common/type";

const getRegistrationStatus = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<RegistrationStatus>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/join/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/join/state`
   *
   * @authorization-required
   *
   * Retrieve Registration status data.
   */
  get: getRegistrationStatus,
};
