import * as ApiBase from "@metamorp/api-base";
import {
  CancelEmail,
  Email,
  EmailItem,
  GetEmailList,
  GetTargetEmails,
  GetTargetList,
  SendEmail,
  TargetItem,
} from "./types";

const cancelEmail = (idx: CancelEmail) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, CancelEmail>({
    method: "PUT",
    path: "/api/v1/email/hist/cancel",
    data: idx,
  });
};

const getTargetList = (data: GetTargetList) => {
  type Response = ApiBase.Types.ApiResBase<
    TargetItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetTargetList>({
    method: "POST",
    path: `/api/v1/email/hist/target/list`,
    data: data,
  });
};

const getTargetIds = (data: GetTargetEmails) => {
  type Response = ApiBase.Types.ApiResBase<string[]>;
  return ApiBase.request<Response, GetTargetEmails>({
    method: "POST",
    path: `/api/v1/email/hist/target/id/all/list`,
    data: data,
  });
};

const getEmailList = (data: GetEmailList) => {
  type Response = ApiBase.Types.ApiResBase<
    EmailItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetEmailList>({
    method: "POST",
    path: `/api/v1/email/hist/list`,
    data: data,
  });
};

const sendEmail = (data: SendEmail) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, SendEmail>({
    method: "POST",
    path: "/api/v1/email/hist/add",
    data: data,
  });
};

const getEmail = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Email>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/email/hist/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/email/hist/cancel`
   *
   * 이메일을 취소합니다.
   *
   * Cancel an Email.
   */
  cancel: cancelEmail,
  /**
   * `POST /api/v1/email/hist/list`
   *
   * 전송된 이메일 목록을 조회합니다.
   *
   * Retrieve sent list of Email.
   */
  getList: getEmailList,
  /**
   * `POST /api/v1/email/hist/target/list`
   *
   * 이메일 전송 대상 목록을 조회합니다.
   *
   * Retrieve target email list.
   */
  getTarget: getTargetList,
  /**
   * `POST /api/v1/email/hist/target/id/all/list`
   *
   * 이메일 전송 대상 전체목록을 조회합니다.
   *
   * Retrieve All target email list.
   */
  getTargetIds: getTargetIds,
  /**
   * `POST /api/v1/email/hist/add`
   *
   * 이메일을 전송합니다.
   *
   * Send an Email.
   */
  send: sendEmail,
  /**
   * `GET /api/v1/email/hist/${idx}`
   *
   * 이메일을 조회합니다.
   *
   * Retrieve an Email.
   */
  get: getEmail,
};
