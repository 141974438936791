import {UDProfileCardVo} from "@/utils/api/features/User/Dashboard/types";

const Card = ( data : UDProfileCardVo) => {

  return (
      <div className="col-12 col-sm-6 col-lg-3">
        <div className="card">
          <div className="card-header">
            <div className="card-title mb-0 d-flex justify-content-between align-items-center">
              {data.title}:
              <span className="badge bg-outline-primary">{data.useCnt} using</span>
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="me-2 lh-1">
                <span className="avatar avatar-md"> <img src={data.imgPath ? data.imgPath : '/static/media/no-image.f147cce9b72afa444184.jpg'} alt=""/> </span>
              </div>
              <div>
                <p className="fw-medium mb-0">{data.mainName ? data.mainName : '기록없음'}</p>
                <span className="text-muted fs-12 d-inline-flex">{data.subName ? data.subName : '기록없음'}<span className={`${data.iconType === 0 ? 'text-warning' : 'text-primary'} lh-1 fs-16 ms-1 d-inline-flex`}><i className="ti ti-discount-check-filled "></i></span> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Card;
