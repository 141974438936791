import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import NewSubscriber from "../Dashboard/NewSubscriber";

const NewSubscribersList = () => {
  return (
    <StatisticsBase<Api.Response.NewSubscriber[], Api.Response.NewSubscriber>
      title="New Subscribers"
      defaultRange={60}
      columns={[
        { key: "country", name: "Country", label: "Country" },
        { key: "userId", name: "ID", label: "ID" },
        { key: "ip", name: "IP", label: "IP" },
        { key: "timeAgo", name: "Time", label: "Time" },
        { key: "userIdx", name: "Dashboard", label: "Dashboard" },
      ]}
      ChartComponent={NewSubscriber}
      errorMessage="Failed fetching New Subscribers data"
      onRequestData={Api.NewSubscribersList.getList}
      getDataForExport={(data) => data || []}
      getDataList={(data) => data || []}
      showTable={false}
    />
  );
};

export default NewSubscribersList;
