import { useState, useRef, useEffect  } from 'react';
import JsonStringViewer from "@/pages/Users/Dashboard/components/JsonStringViewer";
import {UDHistoryResVo} from "@/utils/api/features/User/Dashboard/types";

const Card = (data: UDHistoryResVo) => {

    const [isParamVisible, setIsParamVisible] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleParamVisibility = () => {
        setIsParamVisible((prev) => !prev);
    };

    // 동적 높이 계산
    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(isParamVisible ? contentRef.current.scrollHeight : 0);
        }
    }, [isParamVisible]);

    const methodsMap = [
        {id: 'POST', color: 'success'},
        {id: 'GET', color: 'primary'},
        {id: 'PUT', color: 'warning'},
        {id: 'DELETE', color: 'danger'},
    ];

    const getColorText = (id: string): string => {
        const entry = methodsMap.find(item => item.id === id);
        return entry?.color ?? '';
    };

    return (
        <li>
            <div className="d-flex align-items-center gap-2 justify-content-between">
                <h6 className="mb-1 fs-13 d-flex align-items-center gap-2">
                <span className={`badge bg-${data.logType === 1 ? 'primary' : 'danger'}-transparent`}>
                    {data.logType === 1 ? '요청' : '응답'}
                </span>
                    - {data.apiName}
                    <button
                        className="btn btn-sm btn-primary-light"
                        onClick={toggleParamVisibility}
                    >
                        {isParamVisible ? 'Hide Detail' : 'Show Detail'}
                        <i className="ri-arrow-down-s-line ms-2 align-middle d-inline-block"></i>
                    </button>
                </h6>
                <span className="fs-11 badge bg-light text-dark justify-content-end">{data.regDt}</span>

            </div>

            <div
                style={{
                    height: `${contentHeight}px`,
                    overflow: 'hidden',
                    transition: 'height 0.3s ease-out',
                }}
            >
                <div ref={contentRef}>
                    <pre className="custom-code">
                        <span className={`badge bg-${getColorText(data.method)}-transparent`}>
                            {data.method}
                        </span> {data.url}
                        <JsonStringViewer jsonString={data.param}/>
                    </pre>
                </div>
            </div>

        </li>
    );
};

export default Card;
