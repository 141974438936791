import * as ApiBase from "@metamorp/api-base";

import type {
  Account,
  AccountItem,
  AddAccount,
  GetAccountList,
  UpdateAccount,
} from "./types";

const updateAccount = (data: UpdateAccount) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateAccount>({
    method: "PUT",
    path: "/api/v1/account/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getAccountList = (data: GetAccountList) => {
  type Response = ApiBase.Types.ApiResBase<
    AccountItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetAccountList>({
    method: "POST",
    path: "/api/v1/account/list",
    data: data,
  });
};

const addAccount = (data: AddAccount) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddAccount>({
    method: "POST",
    path: "/api/v1/account/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getAccount = (adminIdx: number) => {
  type Response = ApiBase.Types.ApiResBase<Account>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/account/${adminIdx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/account/update`
   *
   * @authorization-required
   *
   * Update an account.
   */
  update: updateAccount,
  /**
   * `POST /api/v1/account/list`
   *
   * @authorization-required
   *
   * Retrieve a list of accounts.
   */
  getList: getAccountList,
  /**
   * `POST /api/v1/account/add`
   *
   * @authorization-required
   *
   * Add an account.
   */
  add: addAccount,
  /**
   * `GET /api/v1/account/${adminIdx}`
   *
   * @authorization-required
   *
   * Retrieve an account.
   */
  get: getAccount,
};
