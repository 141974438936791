
// Props 타입 정의
interface PeriodTabsProps {
  activeTab: number;  // number 타입으로 지정
  onTabChange: (tab: number) => void;  // 함수 타입 명시
}

const PeriodTabs = (param: PeriodTabsProps) => {
  // 탭 데이터 정의
  const tabs = [
    { id: 0, label: '년간' },
    { id: 1, label: '월간' },
    { id: 2, label: '주간' },
    { id: 3, label: '일간' }
  ];

  // 탭 클릭 핸들러
  const handleTabClick = (tabId: number) => {
      param.onTabChange(tabId);
  };

  return (
      <div className="row stickyPeriodTabs">
        <div className="col-xxl-12 col-xl-12">
          <div className="card custom-card">
            <div className="card-body mb-0">
              <ul className="nav nav-tabs tab-style-1 d-sm-flex d-block mb-0" role="tablist">
                {tabs.map((tab) => (
                    <li
                        key={tab.id}
                        className="nav-item"
                        role="presentation"
                    >
                      <a
                          onClick={() => handleTabClick(tab.id)}
                          className={`nav-link ${param.activeTab === tab.id ? 'active' : ''}`}
                          role="tab"
                          style={{cursor: 'pointer'}}
                      >
                        <i className="ri-check-line"></i> {tab.label}
                      </a>
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PeriodTabs;