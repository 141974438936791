import {useEffect} from "react";
import {UDTopCardResVo} from "@/utils/api/features/User/Dashboard/types";

// window 객체에 google 속성 추가를 위한 declare
declare global {
  interface Window {
    google?: {
      maps: {
        Map: any;
        Marker: any;
        InfoWindow: any;
      };
    };
  }
}

type CardProps = {
  userInfo: UDTopCardResVo
};

const Card = ({ userInfo }: CardProps) => {
  useEffect(() => {
    const loadGoogleMaps = () => {
      if (window.google?.maps) {
        initMap();
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA78IX4Ql39nlCDN2_AR6u7-RwsjPFLFA0`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = initMap;
    };

    const initMap = () => {
      const mapElement = document.getElementById("map");
      const lat = parseFloat(userInfo.lat);
      const lng = parseFloat(userInfo.lon);

      // 좌표 값 검증 및 기본값 설정
      if (isNaN(lat) || isNaN(lng)) {
        console.error("Invalid coordinates:", { lat, lng });
        return; // 잘못된 좌표가 있으면 맵을 초기화하지 않음
      }

      const position = { lat: lat, lng: lng };

      if (mapElement && window.google?.maps) {
        const map = new window.google.maps.Map(mapElement, {
          center: position,
          zoom: 13,
        });

        // 마커 추가
        const marker = new window.google.maps.Marker({
          position: position,
          map: map,
          title: userInfo.nickName || "User Location", // 툴팁 텍스트
        });

        // 정보창 추가 (선택사항)
        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div><strong>${userInfo.nickName || "Unknown User"}</strong><br>Location: ${lat}, ${lng}</div>`,
        });

        // 마커 클릭 이벤트
        marker.addListener("click", () => {
          infoWindow.open(map, marker);
        });
      }
    };

    loadGoogleMaps();
  }, [userInfo.lat, userInfo.lon]);

  return (
      <div className="col-12 col-md-5">
        <div className="card">
          <div className="card-body">
            <div id="map" style={{ width: "100%", height: "310px" }}></div>
          </div>
        </div>
      </div>
  );
};

export default Card;
