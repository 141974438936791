import * as ApiBase from "@metamorp/api-base";
import {
  AddNotice,
  GetNoticeList,
  Notice,
  NoticeItem,
  UpdateNotice,
} from "./types";

const updateNotice = (data: UpdateNotice) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateNotice>({
    method: "PUT",
    path: "/api/v1/board/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getNoticeList = (data: GetNoticeList) => {
  type Response = ApiBase.Types.ApiResBase<
    NoticeItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetNoticeList>({
    method: "POST",
    path: "/api/v1/board/list",
    data: data,
  });
};

const addNotice = (data: AddNotice) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddNotice>({
    method: "POST",
    path: "/api/v1/board/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getNotice = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Notice>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/board/${idx}`,
  });
};

const deleteNotice = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/board/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/board/update`
   *
   * 게시물을 수정합니다.
   *
   * Update a notice.
   */
  update: updateNotice,
  /**
   * `POST /api/v1/board/list`
   *
   * 게시물 목록을 조회합니다.
   *
   * Retrieve a list of notices versions.
   */
  getList: getNoticeList,
  /**
   * `POST /api/v1/board/add`
   *
   * 게시물을 추가합니다.
   *
   * Add a notice.
   */
  add: addNotice,
  /**
   * `GET /api/v1/board/:idx`
   *
   * 게시물을 조회합니다.
   *
   * Retrieve a notice.
   */
  get: getNotice,
  /**
   * `DELETE /api/v1/board/:idx`
   *
   * 게시물을 삭제합니다.
   *
   * Delete a notice.
   */
  delete: deleteNotice,
};
