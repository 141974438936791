import * as ApiBase from "@metamorp/api-base";
import { GetQnaList, Qna, QnaItem, UpdateQna } from "./types";

const updateQna = (data: UpdateQna) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateQna>({
    method: "PUT",
    path: "/api/v1/qna/answer",
    data: data,
  });
};

const getQnaList = (data: GetQnaList) => {
  type Response = ApiBase.Types.ApiResBase<QnaItem[], ApiBase.Types.PagingRes>;
  return ApiBase.request<Response, GetQnaList>({
    method: "POST",
    path: "/api/v1/qna/list",
    data: data,
  });
};

const getQna = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Qna>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/qna/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/qna/answer`
   *
   * Q&A에 답장합니다.
   *
   * Send an answer to a question.
   */
  update: updateQna,
  /**
   * `POST /api/v1/qna/list`
   *
   * Q&A 목록을 조회합니다.
   *
   * Retrieve a list of Q&As.
   */
  getList: getQnaList,
  /**
   * `GET /api/v1/qna/:idx`
   *
   * Q&A를 조회합니다.
   *
   * Retrieve a Qna.
   */
  get: getQna,
};
