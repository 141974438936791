import {useEffect, useState} from "react";
import {formatNumber} from "@/pages/Dashboard/utils";
import BaseChartCard from "@/pages/Users/Dashboard/chart/BaseChartCard";
import {UDFunctionUseResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
    res: UDFunctionUseResVo,
    name: string,
};

const Chart = (data: CardProps) => {

    const [chartOptions, setChartOptions] = useState<any | null>(null);

    // 각 바의 높이를 30px로 설정하고, 여백을 고려하여 계산
    const barHeight = 28; // 개별 바의 높이
    const padding = 50; // 상하 여백
    const legendHeight = 50; // 범례 영역 높이
    // 날짜 수 * 바 높이 + 여백으로 전체 높이 계산
    const dynamicHeight = (data.res.dateList.length * barHeight) + padding + legendHeight;

    useEffect(() => {
        setChartOptions({
            series: [{
                name: 'AI Preparation',
                data: data.res.cnt0List
            }, {
                name: '슬라이싱',
                data: data.res.cnt1List
            }, {
                name: '자동정렬',
                data: data.res.cnt2List
            }, {
                name: '메쉬채우기',
                data: data.res.cnt3List
            }, {
                name: '비우기',
                data: data.res.cnt4List
            }, {
                name: '오프셋',
                data: data.res.cnt5List
            }, {
                name: '복제',
                data: data.res.cnt6List
            }, {
                name: '리메쉬',
                data: data.res.cnt7List
            }, {
                name: '단순화',
                data: data.res.cnt8List
            }, {
                name: '유니온',
                data: data.res.cnt9List
            }, {
                name: '서브트랙트',
                data: data.res.cnt10List
            }, {
                name: '인터섹터',
                data: data.res.cnt11List
            }, {
                name: '서포트',
                data: data.res.cnt12List
            }],
            chart: {
                type: 'bar',
                height: dynamicHeight, // 동적으로 계산된 높이 적용
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '70%', // 바의 높이를 영역의 70%로 설정
                    distributed: false,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            colors: [
                "#5c67f7",
                "#e354d4",
                "#9e5cf7",
                "#fe5454",
                "#0ca3e7",
                "#f7a35c",
                "#54d48e",
                "#f5a3f7",
                "#478024",
                "#67a3f7",
                "#f7d354",
                "#54a3f7",
                "#0c9081"
            ],
            grid: {
                borderColor: '#f2f5f7',
            },
            xaxis: {
                categories: data.res.dateList,
                labels: {
                    show: true,
                    style: {
                        colors: "#8c9097",
                        fontSize: '11px',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                }
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: "#8c9097",
                        fontSize: '11px',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                }
            },
            tooltip: {
                y: {
                    formatter: formatNumber,
                }
            },
            fill: {
                opacity: 1

            },
            legend: {
                show: true,
                position: "top",
            },
        });
    }, [data.res]);

    return (
        <BaseChartCard
            toolTipId={"function"}
            title="일자별 기능별 사용빈도"
            description="선택기간동안 기능별 사용빈도입니다."
            chartOptions={chartOptions}
         />
    );
};

export default Chart;
