import * as ApiBase from "@metamorp/api-base";
import { ConnectionStatus } from "./type";
import { DateRange } from "../common/type";

const getConnectionStatus = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ConnectionStatus>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/connect/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/connect/state`
   *
   * @authorization-required
   *
   * Retrieve Connection status data.
   */
  get: getConnectionStatus,
};
