import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from "reactstrap";
import * as Api from "@/api";
import PageContainer from "@/components/Common/PageContainer";
import ActionStateChart from "@/pages/Users/Dashboard/components/ActionStateChart";
import ConnectionCard from "@/pages/Users/Dashboard/components/ConnectionCard";
import ConnectionStateChart from "@/pages/Users/Dashboard/components/ConnectionStateChart";
import CostCard from "@/pages/Users/Dashboard/components/CostCard";
import DefaultInfoCard from "@/pages/Users/Dashboard/components/DefaultInfoCard";
import FunctionUseStateChart from "@/pages/Users/Dashboard/components/FunctionUseStateChart";
import HistoryCard from "@/pages/Users/Dashboard/components/HistoryCard";
import MaterialRankChart from "@/pages/Users/Dashboard/components/MaterialRankChart";
import ModelRankChart from "@/pages/Users/Dashboard/components/ModelRankChart";
import PeriodTabs from "@/pages/Users/Dashboard/components/PeriodTabs";
import PrinterRankChart from "@/pages/Users/Dashboard/components/PrinterRankChart";
import PrintingRankChart from "@/pages/Users/Dashboard/components/PrintingRankChart";
import ProfileCard from "@/pages/Users/Dashboard/components/ProfileCard";
import SupportRankChart from "@/pages/Users/Dashboard/components/SupportRankChart";
import UserMapCard from "@/pages/Users/Dashboard/components/UserMapCard";

import {
    UDActionStateResVo,
    UDConnectedDaysResVo,
    UDConnectionStateResVo,
    UDConnectionTimeResVo,
    UDDefaultReqVo, UDFunctionUseResVo, UDHistoryResVo,
    UDIncreaseCostResVo, UDRankStateResVo, UDRetentionResVo,
    UDTopCardResVo, UDUseCntResVo
} from "@/utils/api/features/User/Dashboard/types";

import "../../../assets/css/module/userDashboard.css";

const BREADCRUMB_ITEMS = [
    { title: "Members", link: "#" },
    { title: "Dashboard", link: "#" },
];

const UserDashboard = () => {

    const { userIdx } = useParams();

    const [currentPeriod, setCurrentPeriod] = useState(1);

    const params = {
        dateType: currentPeriod,
        userIdx: userIdx ? Number(userIdx) : undefined
    } as UDDefaultReqVo;

    const [userDefaultInfoData, setUserDefaultInfoData] = useState<UDTopCardResVo | null>(null);
    const [increaseCostData, setIncreaseCostData] = useState<UDIncreaseCostResVo | null>(null);
    const [connectedDaysData, setConnectedDaysData] = useState<UDConnectedDaysResVo | null>(null);
    const [connectionTimeData, setConnectionTimeData] = useState<UDConnectionTimeResVo | null>(null);
    const [aIUseCntData, setAIUseCntData] = useState<UDUseCntResVo | null>(null);
    const [aPIUseCntData, setAPIUseCntData] = useState<UDUseCntResVo | null>(null);
    const [retentionData, setRetentionData] = useState<UDRetentionResVo | null>(null);
    const [connectionState, setConnectionState] = useState<UDConnectionStateResVo | null>(null);
    const [actionState, setActionState] = useState<UDActionStateResVo | null>(null);
    const [materialRank, setMaterialRank] = useState<UDRankStateResVo | null>(null);
    const [printerRank, setPrinterRank] = useState<UDRankStateResVo | null>(null);
    const [printingRank, setPrintingRank] = useState<UDRankStateResVo | null>(null);
    const [supportRank, setSupportRank] = useState<UDRankStateResVo | null>(null);
    const [modelRank, setModelRank] = useState<UDRankStateResVo | null>(null);
    const [functionUseState, setFunctionUseState] = useState<UDFunctionUseResVo | null>(null);
    const [userHistory, setUserHistory] = useState<UDHistoryResVo[] | null>(null);

    const fetchPageData = useCallback(async () => {
        await Api.UserDashboard.fetchTopCardData(userIdx).then(response => {
            response && setUserDefaultInfoData(response.data);
        });

        await Api.UserDashboard.fetchUserHistory(userIdx).then(response => {
            response && setUserHistory(response.data);
        });
    }, [userIdx]);

    const fetchPeriodData = useCallback(async () => {

        await Api.UserDashboard.fetchIncreaseCostData(params).then(response => {
            response && setIncreaseCostData(response.data);
        });

        await Api.UserDashboard.fetchConnectedDaysData(params).then(response => {
            response && setConnectedDaysData(response.data);
        });

        await Api.UserDashboard.fetchConnectionTimeData(params).then(response => {
            response && setConnectionTimeData(response.data);
        });

        await Api.UserDashboard.fetchAIUseCntData(params).then(response => {
            response && setAIUseCntData(response.data);
        });

        await Api.UserDashboard.fetchAPIUseCntData(params).then(response => {
            response && setAPIUseCntData(response.data);
        });

        await Api.UserDashboard.fetchRetentionData(params).then(response => {
            response && setRetentionData(response.data);
        });

        await Api.UserDashboard.fetchConnectionState(params).then(response => {
            response && setConnectionState(response.data);
        });

        await Api.UserDashboard.fetchActionState(params).then(response => {
            response && setActionState(response.data);
        });

        await Api.UserDashboard.fetchMaterialRank(params).then(response => {
            response && setMaterialRank(response.data);
        });

        await Api.UserDashboard.fetchPrinterRank(params).then(response => {
            response && setPrinterRank(response.data);
        });

        await Api.UserDashboard.fetchPrintingRank(params).then(response => {
            response && setPrintingRank(response.data);
        });

        await Api.UserDashboard.fetchSupportRank(params).then(response => {
            response && setSupportRank(response.data);
        });

        await Api.UserDashboard.fetchModelRank(params).then(response => {
            response && setModelRank(response.data);
        });

        await Api.UserDashboard.fetchFunctionUseState(params).then(response => {
            response && setFunctionUseState(response.data);
        });

    }, [userIdx, currentPeriod]);

    useEffect(() => {
        fetchPageData();
    }, []);

    useEffect(() => {
        fetchPeriodData();
    }, [currentPeriod]);

    return (
        <React.Fragment>
            <PageContainer title="Dashboard" breadcrumbItems={BREADCRUMB_ITEMS}>
                <Row className="userDashBoard">
                    <Col className="col-12">
                        <Row>
                            {userDefaultInfoData && <DefaultInfoCard userInfo={userDefaultInfoData}/>}
                            {userDefaultInfoData && <UserMapCard userInfo={userDefaultInfoData}/>}
                            {userDefaultInfoData && <ProfileCard
                                title="Printing Profile"
                                useCnt={userDefaultInfoData.printingProfileUseCnt}
                                imgPath={userDefaultInfoData.printingProfileImg}
                                mainName={userDefaultInfoData.printingProfileName}
                                subName={userDefaultInfoData.printingDefalutFalg === 1 ? 'METAMORP' : 'CUSTOM'}
                                iconType={userDefaultInfoData.printingDefalutFalg}
                            />}
                            {userDefaultInfoData && <ProfileCard
                                title="Material"
                                useCnt={userDefaultInfoData.materialUseCnt}
                                imgPath={userDefaultInfoData.materialImg}
                                mainName={userDefaultInfoData.materialName}
                                subName={userDefaultInfoData.materialManufName}
                                iconType={userDefaultInfoData.materialDefalutFlag}
                            />}
                            {userDefaultInfoData && <ProfileCard
                                title="Printer"
                                useCnt={userDefaultInfoData.printerUseCnt}
                                imgPath={userDefaultInfoData.printerImg}
                                mainName={userDefaultInfoData.printerName}
                                subName={userDefaultInfoData.printerManufName}
                                iconType={userDefaultInfoData.printerDefalutFlag}
                            />}
                            {userDefaultInfoData && <ProfileCard
                                title="Support Profile"
                                useCnt={userDefaultInfoData.supportProfileUseCnt}
                                imgPath={userDefaultInfoData.supportProfileImg}
                                mainName={userDefaultInfoData.supportProfileName}
                                subName={userDefaultInfoData.supportDefalutFalg === 1 ? 'METAMORP' : 'CUSTOM'}
                                iconType={userDefaultInfoData.supportDefalutFalg}
                            />}
                        </Row>

                        <PeriodTabs
                            activeTab={currentPeriod}
                            onTabChange={setCurrentPeriod}
                        />

                        <Row>
                            {increaseCostData &&
                                <CostCard data={increaseCostData} periodType={currentPeriod} cardType={0}/>}
                            {increaseCostData &&
                                <CostCard data={increaseCostData} periodType={currentPeriod} cardType={1}/>}
                            {increaseCostData &&
                                <CostCard data={increaseCostData} periodType={currentPeriod} cardType={2}/>}
                        </Row>

                        <Row>
                            {connectedDaysData && <ConnectionCard
                                cardType={0}
                                rate={connectedDaysData.rateChange}
                                value={`${connectedDaysData.daysNumber}일`}
                            />}
                            {connectionTimeData && <ConnectionCard
                                cardType={1}
                                rate={connectionTimeData.rateChange}
                                value={connectionTimeData.usageTimeStr}
                            />}
                            {aIUseCntData && <ConnectionCard
                                cardType={2}
                                rate={aIUseCntData.rateChange}
                                value={`${aIUseCntData.useCount}회`}
                            />}
                            {aPIUseCntData && <ConnectionCard
                                cardType={3}
                                rate={aPIUseCntData.rateChange}
                                value={`${aPIUseCntData.useCount}회`}
                            />}
                            {retentionData && <ConnectionCard
                                cardType={4}
                                rate={retentionData.rateChange}
                                value={`${retentionData.retention}%`}
                            />}
                        </Row>

                        <Row>
                            <Col className="col-12 col-md-6">
                                {userDefaultInfoData && connectionState &&
                                    <ConnectionStateChart name={userDefaultInfoData.nickName} res={connectionState}/>}
                            </Col>
                            <Col className="col-12 col-md-6">
                                {userDefaultInfoData && actionState &&
                                    <ActionStateChart name={userDefaultInfoData.nickName} res={actionState}/>}
                            </Col>
                            <Col className="col-12 col-md-6">
                                {userDefaultInfoData && materialRank &&
                                    <MaterialRankChart name={userDefaultInfoData.nickName} res={materialRank}/>}
                            </Col>
                            <Col className="col-12 col-md-6">
                                {userDefaultInfoData && printerRank &&
                                    <PrinterRankChart name={userDefaultInfoData.nickName} res={printerRank}/>}
                            </Col>
                            <Col className="col-12 col-md-6">
                                {userDefaultInfoData && printingRank &&
                                    <PrintingRankChart name={userDefaultInfoData.nickName} res={printingRank}/>}
                            </Col>
                            <Col className="col-12 col-md-6">
                                {userDefaultInfoData && supportRank &&
                                    <SupportRankChart name={userDefaultInfoData.nickName} res={supportRank}/>}
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-12 col-md-8">
                                <Row>
                                    <Col className="col-12">
                                        {userDefaultInfoData && modelRank &&
                                            <ModelRankChart name={userDefaultInfoData.nickName} res={modelRank}/>}
                                    </Col>
                                    <Col className="col-12">
                                        {userDefaultInfoData && functionUseState &&
                                            <FunctionUseStateChart name={userDefaultInfoData.nickName} res={functionUseState}/>}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-12 col-md-4">
                                {userHistory &&
                                    <HistoryCard data={userHistory}
                                                 userIdx={Number(userIdx)}/>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </PageContainer>
        </React.Fragment>
    );
};

export default UserDashboard;
