import {UDConnectedDayCardDataVo} from "@/utils/api/features/User/Dashboard/types";

const Card = ( data : UDConnectedDayCardDataVo) => {

    const cardColorTypeMap = [
        {id: 0, color: 'primary', title: '접속일수'},
        {id: 1, color: 'primary1', title: '접속시간'},
        {id: 2, color: 'primary2', title: 'AI사용횟수'},
        {id: 3, color: 'primary3', title: 'API사용횟수'},
        {id: 4, color: 'secondary', title: '리텐션'},
    ];

    const getColorText = (id: number): string => {
        const entry = cardColorTypeMap.find(item => item.id === id);
        return entry?.color ?? '';
    };

    const getTitle = (id: number): string => {
        const entry = cardColorTypeMap.find(item => item.id === id);
        return entry?.title ?? '';
    };

    return (
        <div className="col-6 col-lg">
            <div className={`card border border-${getColorText(data.cardType)} border-opacity-25 custom-card`}>
                <div className="card-body">
                    <div className="d-flex gap-2 align-items-center my-1">
                        <div><span className="mb-1 d-block text-muted">{getTitle(data.cardType)}</span> <h4 className="mb-1">{data.value}</h4>
                            <div className="text-muted fs-13"><span className="">  Increased By  </span>
                                <span className={`badge bg-${data.rate > 0 ? 'success' : 'danger'}-transparent text-${data.rate > 0 ? 'success' : 'danger'} align-middle rounded-pill`}>{data.rate}%<i className={`ti ti-arrow-narrow-${data.rate > 0 ? 'up' : 'down'}`}></i></span>
                            </div>
                        </div>
                        <div className={`avatar avatar-lg avatar-rounded bg-${getColorText(data.cardType)}-transparent ms-auto shadow-sm`}>
                            {data.cardType === 0 && <i className="ri-computer-line fs-3"></i>}
                            {data.cardType === 1 && <i className="ri-time-line fs-3"></i>}
                            {data.cardType === 2 && <span className="fs-6">AI</span>}
                            {data.cardType === 3 && <span className="fs-6">API</span>}
                            {data.cardType === 4 && <i className="ri-history-fill fs-3"></i>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Card;
