import { ReactNode } from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import noImg from "../../assets/images/no-image.jpg";

type NewSubscriberProps = {
  data: Api.Response.NewSubscriber[] | null;
  isHeader?: boolean;
  isFullWidth?: boolean;
  children?: ReactNode;
};

const NewSubscriber = ({
  data,
  isHeader,
  isFullWidth = false,
  children,
}: NewSubscriberProps) => {

  const navigate = useNavigate();
  const moveToDashBoard = (userIdx: number) => {
    navigate(`/users/dashboard/${userIdx}${window.location.search}`);
  };

  return (
    <ChartBase
      title="최근접속자"
      tooltipId="newSubscriberInfo"
      tooltipContent="최근 접속한 사용자 5명입니다."
      redirectUrl="/new-subscribers"
      minHeight="360px"
      isHeader={isHeader}
      cardBodyClass={isFullWidth ? "px-5" : "p-0"}
      isFullWidth={isFullWidth}
    >
      {children}
      {isFullWidth && (
        <ul className="list-group list-group-flush mb-0 border-bottom">
          <li className="list-group-item">
            <div className="d-flex flex-wrap align-items-center justify-content-between flex-xl-nowrap pl-3">
              <div className="d-flex align-items-center">
                <div
                    className="col-10 col-md-8 text-start"
                    style={{minWidth: "200px"}}
                >
                  <span className="fs-14 fw-bold">프로필</span>
                </div>
              </div>
              <div className="col-2 col-md-2 text-center">
                <span className="fs-14 1 fw-bold">IP</span>
              </div>
              <div className="col-2 col-md-2 text-center">
                <span className=" fs-14  fw-bold">일자</span>
              </div>
              <div className="col-2 col-md-2 text-end">
                <span className=" fs-14  fw-bold">대시보드</span>
              </div>
            </div>
          </li>
        </ul>
      )}
      <ul className="list-group list-group-flush mb-0">
        {data?.map((user) => (
                <li className="list-group-item" key={user.userId}>
                  <div className="d-flex flex-wrap align-items-center justify-content-between flex-xl-nowrap pl-3">
                    <div className="d-flex align-items-center">
                      <div className="me-2 lh-1 col-2 col-md-2">
                    <span className="avatar avatar-md avatar-rounded">
                      <img src={user.imgPath || noImg} alt="User Photo"/>
                    </span>
                      </div>
                      <div className="col-8 col-md-6" style={{minWidth: "200px"}}>
                        <p className="mb-0">
                          <span className="fw-bold text-dark">{user.nickName}</span>{" "}
                          <span className=" fw-bold text-primary">
                        {user.country}
                      </span>
                        </p>
                        <span className="text-muted fs-14 fw-bold">
                      {user.userId}
                    </span>
                      </div>
                    </div>
                    <div className="col-2 col-md-2 text-end">
                  <span className="text-muted fs-12 mt-1 ms-1 fw-bold d-flex align-right">
                    {user.ip}
                  </span>
                    </div>
                    <div className="col-2 col-md-2 text-end">
                  <span className="text-muted fs-12 mt-1 ms-1 fw-bold">
                    {user.timeAgo}
                  </span>
                    </div>
                    {isFullWidth && <div className="col-2 col-md-2 text-end">
                      <Button
                          type="button"
                          size="sm"
                          color="dark"
                          onClick={() => moveToDashBoard(user.userIdx)}
                      >
                        보기
                      </Button>
                    </div>}
                  </div>
                </li>
            ))}
      </ul>
    </ChartBase>
  );
};

export default NewSubscriber;
