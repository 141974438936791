import * as ApiBase from "@metamorp/api-base";
import { GetSlicingList, SlicingItem } from "./types";

const getSlicingList = (data: GetSlicingList) => {
  type Response = ApiBase.Types.ApiResBase<
    SlicingItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetSlicingList>({
    method: "POST",
    path: "/api/v1/slicing/list",
    data: data,
  });
};

export default {
  /**
   * `POST /api/v1/feature/list`
   *
   * 슬라이싱 요청 목록을 조회합니다.
   *
   * Retrieve a list of slicing requests.
   */
  getList: getSlicingList,
};
