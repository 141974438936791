import {useEffect, useState} from "react";
import {formatNumber} from "@/pages/Dashboard/utils";
import BaseChartCard from "@/pages/Users/Dashboard/chart/BaseChartCard";
import {UDRankStateResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
    res: UDRankStateResVo,
    name: string,
};

const Chart = (data: CardProps) => {

    const [chartOptions, setChartOptions] = useState<any | null>(null);

    useEffect(() => {
        setChartOptions({
            series: [
                {
                    name: "메타몰프 평균",
                    type: "area",
                    data: data.res.metaAvgList
                },
                {
                    name: data.name,
                    type: "line",
                    data: data.res.userUseCntList
                }
            ],
            chart: {
                height: 320,
                type: 'line',
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 2,
                    left: 0,
                    blur: 6,
                    color: ['rgb(227, 84, 212)',"var(--primary-color)"],
                    opacity: 0.3
                },
                toolbar: { show: false }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                position: "top",
            },
            stroke: {
                curve: 'smooth',
                width: ['2','2'],
                dashArray: [2 , 1]
            },
            fill: {
                type: ['gradient','solid'],
                gradient:{
                    opacityFrom: 0.1,
                    opacityTo: 0.2,
                    shadeIntensity: 0.1,
                },
            },
            grid: {
                borderColor: '#f5f4f4',
                strokeDashArray: 3
            },
            colors: ["rgb(255,26,107)","rgb(89,0,185)"],
            yaxis: {
                labels: {
                    formatter: formatNumber,
                },
            },
            xaxis: {
                type: 'week',
                categories: data.res.nameList,
                axisBorder: {
                    show: true,
                    color: 'rgba(119, 119, 142, 0.05)',
                    offsetX: 0,
                    offsetY: 0,
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: 'rgba(119, 119, 142, 0.05)',
                    width: 6,
                    offsetX: 0,
                    offsetY: 0
                },
                labels: {
                    rotate: -90
                }
            }
        });
    }, [data.res]);

    return (
        <BaseChartCard
            toolTipId={"model"}
            title="상위모델 사용빈도"
            description="선택기간동안 모델별 사용빈도입니다."
            chartOptions={chartOptions}
         />
    );
};

export default Chart;
