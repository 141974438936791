import * as ApiBase from "@metamorp/api-base";
import { AddCategory, CategoryItem, UpdateCategory } from "./types";

const updateCategory = (data: UpdateCategory) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateCategory>({
    method: "PUT",
    path: "/api/v1/category/update",
    data: data,
  });
};

const getCategoryList = () => {
  type Response = ApiBase.Types.ApiResBase<CategoryItem[]>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/category/list",
  });
};

const addCategory = (data: AddCategory) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddCategory>({
    method: "POST",
    path: "/api/v1/category/add",
    data: data,
  });
};

const deleteCategory = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/category/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/category/update`
   *
   * 카테고리 정보를 수정합니다.
   *
   * Update category information.
   */
  update: updateCategory,
  /**
   * `POST /api/v1/category/list`
   *
   * 카테고리 목록을 조회합니다.
   *
   * Retrieve a list of categories.
   */
  getList: getCategoryList,
  /**
   * `POST /api/v1/category/add`
   *
   * 카테고리 정보를 추가합니다.
   *
   * Add a category information.
   */
  add: addCategory,
  /**
   * `DELETE /api/v1/category/:idx`
   *
   * 카테고리를 삭제합니다.
   *
   * Delete a category.
   */
  delete: deleteCategory,
};
