import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { CustomModal } from "./styled";

type DeleteModalProps = {
  data: string | undefined;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onHandleDelete: () => void;
};

const DeleteModal = ({
  isLoading,
  data,
  isOpen,
  setIsOpen,
  onHandleDelete,
}: DeleteModalProps) => {
  const [deleteText, setDeleteText] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setDeleteText("");
    }
  }, [isOpen]);

  return (
    <Col sm={6} md={4} xl={3} className="mt-4">
      <CustomModal
        style={{ maxWidth: "33.75rem" }}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        centered={true}
      >
        <ModalHeader className="text-center">
          [{data}]를 삭제하시겠습니까?
        </ModalHeader>
        <ModalBody>
          <div className="px-4 w-100 text-center">
            <h4 className="text-danger mt-3">
              &quot;삭제하기&quot;를 입력해주세요.
            </h4>
            <Input
              type="text"
              placeholder="삭제하기를 입력해주세요"
              className="form-control"
              value={deleteText}
              onChange={(e) => setDeleteText(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <div className="d-flex flex-column align-items-center">
              <Spinner className="mb-3" color="secondary" />
            </div>
          ) : (
            <>
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={onHandleDelete}
                disabled={isLoading || deleteText !== "삭제하기"}
              >
                확인
              </Button>
              <Button
                type="button"
                onClick={() => setIsOpen(false)}
                color="danger"
                className="waves-effect"
                disabled={isLoading}
              >
                취소
              </Button>
            </>
          )}
        </ModalFooter>
      </CustomModal>
    </Col>
  );
};

export default DeleteModal;
