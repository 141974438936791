import {
    Button,
    Col,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {CustomModal} from "./styled";

export type ModalConfig = {
  isOpen: boolean;
  title: string;
  subTitle?: string;
  onConfirm: () => void;
};

type CommonConfirmModalProps = {
  config: ModalConfig;
  setIsOpen: (isOpen: boolean) => void;
};

const CommonConfirmModal = ({
  config,
  setIsOpen,
}: CommonConfirmModalProps) => {
  return (
      <div>
        <Col sm={6} md={4} xl={3} className="mt-4">
          <CustomModal
              isOpen={config.isOpen}
              toggle={() => setIsOpen(false)}
              centered
          >
            <ModalHeader>{config.title}</ModalHeader>
            <ModalBody>
              <p>
                {config.subTitle || "잘못된 정보가 없는지 주의해주세요."}
              </p>
            </ModalBody>
            <ModalFooter>
              <>
                <Button
                    type="button"
                    color="success"
                    className="waves-effect waves-light"
                    onClick={() => {
                      config.onConfirm();
                      setIsOpen(false);
                    }}
                >
                  확인
                </Button>
                <Button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    color="danger"
                    className="waves-effect"
                >
                  취소
                </Button>
              </>
            </ModalFooter>
          </CustomModal>
        </Col>
      </div>
  );
};

export default CommonConfirmModal;
