import {useEffect, useRef, useState} from "react";
import ApexCharts from "apexcharts";
import {DashboardTooltip} from "@/pages/Dashboard/styled";

type CardProps = {
    toolTipId: string,
    title: string,
    description: string
    chartOptions: any
};

const BaseChartCard = ({toolTipId, title, description, chartOptions}: CardProps) => {

    const [isHovered, setIsHovered] = useState(false);
    const chartRef = useRef<HTMLDivElement | null>(null);
    const chartInstanceRef = useRef<ApexCharts | null>(null);

    useEffect(() => {
        if (chartRef.current && chartOptions) {
            if (!chartInstanceRef.current) {
                chartInstanceRef.current = new ApexCharts(chartRef.current, chartOptions);
                chartInstanceRef.current?.render();
            } else {
                chartInstanceRef.current?.updateOptions(chartOptions); // 옵션 업데이트
            }
        }

        return () => {
            chartInstanceRef.current?.destroy(); // 컴포넌트 언마운트 시 제거
            chartInstanceRef.current = null;
        };
    }, [chartOptions]);

    return (
        <div className="card custom-card">
            <div className="card-header justify-content-between">
                <div className="card-title"> {title}
                    <i
                        className="ri-question-line align-bottom ps-1"
                        id={toolTipId}
                        style={{color: "#6e829f"}}
                    ></i>
                    <DashboardTooltip
                        placement="top"
                        isOpen={isHovered}
                        target={toolTipId}
                        toggle={() => setIsHovered((prev) => !prev)}
                    >
                        <span dangerouslySetInnerHTML={{__html: description}}/>
                    </DashboardTooltip>

                </div>
            </div>
            <div className="card-body">
                <div style={{minHeight: "330px"}}>
                    <div ref={chartRef}></div>
                </div>
            </div>
        </div>
    );
};

export default BaseChartCard;
