import {useEffect, useState} from "react";
import {formatNumber} from "@/pages/Dashboard/utils";
import BaseChartCard from "@/pages/Users/Dashboard/chart/BaseChartCard";
import {UDRankStateResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
    res: UDRankStateResVo,
    name: string,
};

const Chart = (data: CardProps) => {

    const [chartOptions, setChartOptions] = useState<any | null>(null);

    useEffect(() => {
        setChartOptions({
            series: [
                {
                    name: "메타몰프 평균",
                    type: "area",
                    data: data.res.metaAvgList,
                },
                {
                    name: data.name,
                    type: "bar",
                    data: data.res.userUseCntList,
                },
            ],
            chart: {
                type: "area",
                height: 300,
                animations: {
                    speed: 500,
                },
                toolbar: {
                    show: false,
                },
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 8,
                    left: 0,
                    blur: 4,
                    color: "#000",
                    opacity: 0.08,
                },
            },
            colors: [ "rgb(189,67,0)","rgba(255,166,236,0.89)"],
            dataLabels: {
                enabled: false,
            },
            grid: {
                borderColor: "#f1f1f1",
                strokeDashArray: 3,
            },
            fill: {
                type: ['gradient','solid'],
                gradient:{
                    opacityFrom: 0.1,
                    opacityTo: 0.2,
                    shadeIntensity: 0.1,
                },
            },
            stroke: {
                curve: ["straight","smooth"],
                width: [2, 1.5],
                dashArray: [4, 5],
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                categories: data.res.nameList,
            },
            yaxis: {
                labels: {
                    formatter: formatNumber,
                },
            },
            legend: {
                show: true,
                position: "top",
            },
            plotOptions: {
                bar: {
                    columnWidth: "20%",
                    borderRadius: 3,
                    borderRadiusApplication: "end",
                    borderRadiusWhenStacked: "last",
                },
            },
        });
    }, [data.res]);

    return (
        <BaseChartCard
            toolTipId={"supprot"}
            title="서포트프로파일 사용순위"
            description="메타몰프 평균대비 사용자의 서포트프로파일 사용순위입니다."
            chartOptions={chartOptions}
         />
    );
};

export default Chart;
