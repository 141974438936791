import {formatNumber} from "@/pages/Dashboard/utils";
import {UDIncreaseCostResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
    data: UDIncreaseCostResVo,
    periodType: number,
    cardType: number
};

const Card = ({data, periodType, cardType}: CardProps) => {

    const periodTypeMap = [
        {id: 0, label: '년간'},
        {id: 1, label: '월간'},
        {id: 2, label: '주간'},
        {id: 3, label: '일간'}
    ];

    const descriptionMap = [
        {id: 0, label: '매출상승 기대수치에요!'},
        {id: 1, label: '절감시간 수치에요!'},
        {id: 2, label: '업무효율 상승수치에요!'},
    ];

    const getPeriodLabel = (id: number): string => {
        const entry = periodTypeMap.find(item => item.id === id);
        return entry?.label ?? '알 수 없음';
    };

    const getCardLabel = (id: number): string => {
        const entry = descriptionMap.find(item => item.id === id);
        return entry?.label ?? '알 수 없음';
    };

    return (
        <div className="col-6 col-lg-4">
            <div className="card custom-card flex-row">
                <div className="card-body bg-light m-2 rounded-1">
                    <div className="d-flex flex-wrap align-items-center gap-3 flex-xl-nowrap">
                        <div>
                            {cardType === 0 &&
                                <span className="avatar avatar-md bg-primary-gradient svg-white"> <span className="fs-3">₩</span> </span>}
                            {cardType === 1 &&
                                <span className="avatar avatar-md bg-primary1-gradient svg-white"> <i className="ri-timer-flash-line fs-3"></i> </span>}
                            {cardType === 2 &&
                                <span className="avatar avatar-md bg-primary2-gradient svg-white"> <i className="ri-thumb-up-line fs-3"></i> </span>}
                        </div>
                        <div>
                            <p className="mb-0 ">{getPeriodLabel(periodType)} {getCardLabel(cardType)}</p>
                            {cardType === 0 &&<h5 className="fw-semibold mb-0">₩{formatNumber(data.increaseCost)}
                                <span className="text-muted fs-12 fw-normal ms-1"> 메타몰프 평균대비
                                  <span className={`badge bg-${data.compAvg > 0 ? 'success' : 'danger'}-transparent text-${data.compAvg > 0 ? 'success' : 'danger'} align-middle rounded-pill`}>{data.compAvg}%
                                      <i className={`ti ti-arrow-narrow-${data.compAvg > 0 ? 'up' : 'down'}`}></i>
                                  </span>
                                </span>
                            </h5>}
                            {cardType === 1 && <h5 className="fw-semibold mb-0">{formatNumber(data.saveTimeStr)}</h5>}
                            {cardType === 2 && <h5 className="fw-semibold mb-0">{formatNumber(data.increaseRate)}%</h5>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
