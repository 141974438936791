import React from 'react';

interface JsonStringViewerProps {
    jsonString: string;
}

const JsonStringViewer: React.FC<JsonStringViewerProps> = ({ jsonString }) => {
    const parseAndFormat = (str: string): string => {
        try {
            // 문자열을 파싱하여 객체로 변환
            const parsed = JSON.parse(str);
            // 들여쓰기와 줄바꿈을 포함하여 다시 문자열로 변환
            return JSON.stringify(parsed, null, 2);
        } catch (e) {
            return '파라메타가 없거나, 유효하지 않은 JSON 문자열입니다.';
        }
    };

    return (
        <div className="rounded-lg bg-gray-50 p-4">
            <pre className="mb-0 text-sm text-break">
                <code className="text-gray-800">
                    {parseAndFormat(jsonString)}
                </code>
            </pre>
        </div>
    );
};

export default JsonStringViewer;