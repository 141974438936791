import React, {useCallback, useEffect, useMemo, useState} from "react";
import {format} from "date-fns";
import {toast, ToastContainer} from "react-toastify";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainer from "@/components/Common/CustomTableContainer";
import {useStateRef} from "@/helpers/hooks";
import {ImageCell} from "@/pages/Utility/CustomCells";
import {UserItem} from "@/utils/api/features/User/types";
import useUserStore from "@/zustandStore/userStore";

type UserFinderProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    isMultiple?: false;
    onSelectComplete?: (selectedUsers: number[]) => void; // 콜백 함수 추가
};

const statusOptions = [
    {label: "전체", value: -1},
    {label: "정상", value: 0},
    {label: "탈퇴", value: 1},
    {label: "정지", value: 2},
];

const registrationTypeOptions = [
    {label: "전체", value: -1},
    {label: "자체가입", value: 0},
    {label: "관리자등록", value: 1},
];

type CellProps = {
    value: string;
}

// Date 형식 셀
const DateCell: React.FC<CellProps> = ({ value }) => (
    <span>{format(new Date(value), "yyyy-MM-dd")}</span>
);

// 상태 표시 셀
type StatusCellProps = {
    value: number;  // 상태값은 숫자만 받음
}

const StatusCell: React.FC<StatusCellProps> = ({ value }) => {
    let statusColor = "";
    switch (value) {
        case 0:
            statusColor = "#1cbb8c";
            break;
        case 1:
            statusColor = "#ff3d5f";
            break;
        case 2:
            statusColor = "#75788d";
            break;
        default:
            statusColor = "black";
            break;
    }

    return (
        <span style={{ color: statusColor }}>
            {statusOptions.find((option) => option.value === value)?.label}
        </span>
    );
};

// 등록 구분 셀
type UserRegTypeCellProps = {
    value: number;  // 등록 구분도 숫자만 받음
}

const UserRegTypeCell: React.FC<UserRegTypeCellProps> = ({ value }) => {
    let regTypeColor = "";
    switch (value) {
        case 0:
            regTypeColor = "black";
            break;
        case 1:
            regTypeColor = "#5664d2";
            break;
        default:
            regTypeColor = "black";
            break;
    }

    return (
        <span style={{ color: regTypeColor }}>
            {registrationTypeOptions.find((option) => option.value === value)?.label}
        </span>
    );
};

// Cell 컴포넌트들의 Props 타입 정의
type ObjectCellProps<T = any> = {
    cell: {
        value: T;
    };
    value?: T;  // 직접 value prop으로 전달되는 경우
}

const UserFinderModal = ({isOpen, setIsOpen, isMultiple, onSelectComplete}: UserFinderProps) => {

    const handleToggle = () => setIsOpen(!isOpen);
    const [searchKeyword, setSearchKeyword] = useState("");
    const searchKeywordRef = useStateRef(searchKeyword);
    const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0].value);
    const [selectedRegType, setSelectedRegType] = useState(registrationTypeOptions[0].value);
    const [clearTrigger, setClearTrigger] = useState(false);
    const [customPageSize, setCustomPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectUserIdx, setSelectUserIdx] = useState<number[]>([]);

    const { users, setUsers, loading, setLoading, setError } = useUserStore();
    const [totalPage, setTotalPage] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);

    const handleSelectComplete = () => {
        if (!selectUserIdx.length) {
            toast.error("먼저 사용자를 선택해주세요", {
                autoClose: 3000,
            });
            return;
        }
        if (onSelectComplete) {
            onSelectComplete(selectUserIdx);
        }
        setIsOpen(false); // 선택 완료 후 모달 닫기
        clearFilters();
    };

    const handleCheckboxChange = useCallback((userIdx: number) => {
        setSelectUserIdx((prev) => {

            const newSelection = prev.includes(userIdx)
                ? prev.filter((id) => id !== userIdx)
                : [...prev, userIdx];

            if(newSelection.length == 2 && !isMultiple){
                toast.error("한명만 선택가능합니다.", {
                    autoClose: 3000,
                });
                return [...prev];
            }

            return newSelection;
        });
    }, []); // 의존성 배열이 비어있어도 됨 (setState 콜백을 사용하므로)

    const handleCheckAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                setSelectUserIdx(users.map((user: UserItem) => user.userIdx));
            } else {
                setSelectUserIdx([]);
            }
        },
        [users],
    );

    const selectProps = [
        {
            selectTitle: '회원 상태',
            options: statusOptions,
            selectedOption: selectedStatus,
            setSelectedOption: setSelectedStatus
        },
        {
            selectTitle: '등록 구분',
            options: registrationTypeOptions,
            selectedOption: selectedRegType,
            setSelectedOption: setSelectedRegType
        },
    ];


    const clearFilters = async () => {
        setSearchKeyword("");
        setDateRange(["", ""]);
        setSelectedStatus(statusOptions[0].value);
        setSelectedRegType(registrationTypeOptions[0].value);
        setCurrentPage(0);
        setClearTrigger((prev) => !prev);
        setSelectUserIdx([]);
    };

    const handleSearch = () => {
        setCurrentPage(0);
        fetchUsers();
    };

    const fetchUsers = useCallback(async () => {

        setLoading(true);
        setError(null);

        const searchKeyword = searchKeywordRef.current;
        const requestData = {
            pageSize: customPageSize,
            nowPage: currentPage + 1,
            pageGroup: 10,
            keyword: searchKeyword,
            stateFlag: selectedStatus,
            regFlag: selectedRegType,
            startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
            endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
        };

        await Api.User.getList(requestData).then(response => {
            if(response){
                setTotalPage(response.paging.totalPage);
                setTotalUsers(response.paging.totalRecord);
                setUsers(response.data);
            }
        }).catch(reason => {
            setError(reason.message);
        }).finally(() => {
            setLoading(false);
        });

    }, [
        currentPage,
        customPageSize,
        selectedRegType,
        selectedStatus,
        dateRange[1],
        clearTrigger,
    ]);

    useEffect(() => {
        fetchUsers();
    }, [
        currentPage,
        customPageSize,
        selectedRegType,
        selectedStatus,
        dateRange[1],
        clearTrigger,
    ]);

    const columns = useMemo(
        () => [
            {
                Header: "No",
                accessor: "userIdx",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "프로필",
                accessor: "imgPath",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }: ObjectCellProps<string>) => (
                    <ImageCell value={value} isUser="true" />
                ),
            },
            {
                Header: "ID",
                accessor: "userId",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "닉네임",
                accessor: "nickName",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "직업",
                accessor: "job",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "전화번호",
                accessor: "phone",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "국가",
                accessor: "countryName",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "회원상태",
                accessor: "stateFlag",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell }: ObjectCellProps<number>) => <StatusCell value={cell.value} />,
            },
            {
                Header: "등록구분",
                accessor: "regFlag",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell }: ObjectCellProps<number>) => <UserRegTypeCell value={cell.value} />,
            },
            {
                Header: "가입일자",
                accessor: "regDt",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell }: ObjectCellProps<string>) => <DateCell value={cell.value} />,
            },
        ],
        [],
    );

    return (
        <Modal size="xl" isOpen={isOpen} toggle={handleToggle}>
            <ModalHeader toggle={handleToggle}>대상찾기</ModalHeader>
            <ModalBody>
                <Row>
                    <CommonControlPanel
                        placeholder="이름, ID, 전화번호로 검색해주세요."
                        setSearchKeyword={setSearchKeyword}
                        searchKeyword={searchKeyword}
                        clearFilters={clearFilters}
                        onSearch={handleSearch}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        select={selectProps}
                    />
                    <CustomTableContainer
                        sortByIdx="userIdx"
                        isLoading={loading}
                        columns={columns || []}
                        data={users || []}
                        showPageSizeOptions={false}
                        customPageSize={customPageSize}
                        totalPage={totalPage}
                        totalRecord={totalUsers}
                        setCustomPageSize={setCustomPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        className="custom-header-css table align-middle table-nowrap"
                        handleCheckboxChange={handleCheckboxChange}
                        selectedIds={selectUserIdx} // selectUserIdx를 직접 전달
                        handleCheckAll={handleCheckAll}
                        showCheckboxColumn
                    />
                    <Col
                        xs={12}
                        style={{padding: "0 6px"}}
                        className="d-flex justify-content-end"
                    >
                        <Button
                            color="dark"
                            className="btn waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
                            onClick={handleToggle}
                        >
                            취소
                        </Button>
                        <Button
                            color="primary"
                            className="btn waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
                            onClick={handleSelectComplete}
                        >
                            선택완료
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
            <ToastContainer/>
        </Modal>
    );
};

export default UserFinderModal;
