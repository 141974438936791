import * as ApiBase from "@metamorp/api-base";
import {
  AddClient,
  Client,
  ClientItem,
  GetClientList,
  UpdateClient,
  UpdateClientSecret,
} from "./types";

const updateClient = (data: UpdateClient) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateClient>({
    method: "PUT",
    path: "/api/v1/client/info/update",
    data: data,
  });
};

const updateClientSecret = (data: UpdateClientSecret) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateClientSecret>({
    method: "PUT",
    path: "/api/v1/client/info/secret/update",
    data: data,
  });
};

const getClientList = (data: GetClientList) => {
  type Response = ApiBase.Types.ApiResBase<
    ClientItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetClientList>({
    method: "POST",
    path: "/api/v1/client/info/list",
    data: data,
  });
};

const addClient = (data: AddClient) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddClient>({
    method: "POST",
    path: "/api/v1/client/info/add",
    data: data,
  });
};

const getClient = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Client>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/client/info/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/client/info/update`
   *
   * 고객사 정보를 수정합니다.
   *
   * Update client information.
   */
  update: updateClient,
  /**
   * `PUT /api/v1/client/info/secret/update`
   *
   * 고객사 시크릿을 수정합니다.
   *
   * Update client secret.
   */
  updateSecret: updateClientSecret,
  /**
   * `POST /api/v1/client/info/list`
   *
   * 고객사 목록을 조회합니다.
   *
   * Retrieve a list of clients.
   */
  getList: getClientList,
  /**
   * `POST /api/v1/client/info/add`
   *
   * 고객사 정보를 추가합니다.
   *
   * Add a client information.
   */
  add: addClient,
  /**
   * `GET /api/v1/client/info/:idx`
   *
   * 고객사 정보를 조회합니다.
   *
   * Retrieve a client information.
   */
  get: getClient,
};
