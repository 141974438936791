import * as ApiBase from "@metamorp/api-base";
import {
  AddVersion,
  GetVersionList,
  UpdateVersion,
  Version,
  VersionItem,
} from "./types";

const updateVersion = (data: UpdateVersion) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateVersion>({
    method: "PUT",
    path: "/api/v1/version/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getVersionList = (data: GetVersionList) => {
  type Response = ApiBase.Types.ApiResBase<
    VersionItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetVersionList>({
    method: "POST",
    path: "/api/v1/version/list",
    data: data,
  });
};

const addVersion = (data: AddVersion) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddVersion>({
    method: "POST",
    path: "/api/v1/version/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getVersion = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Version>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/version/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/version/update`
   *
   * 앱 버전을 수정합니다.
   *
   * Update an app version.
   */
  update: updateVersion,
  /**
   * `POST /api/v1/version/list`
   *
   * 앱 버전 목록을 조회합니다.
   *
   * Retrieve a list of app versions.
   */
  getList: getVersionList,
  /**
   * `POST /api/v1/version/add`
   *
   * 앱 버전을 추가합니다.
   *
   * Add an app version.
   */
  add: addVersion,
  /**
   * `GET /api/v1/version/:idx`
   *
   * 앱 버전을 조회합니다.
   *
   * Retrieve an app version.
   */
  get: getVersion,
};
