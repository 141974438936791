import * as ApiBase from "@metamorp/api-base";
import {
  AddPrinter,
  GetPrinterList,
  Printer,
  PrinterItem,
  UpdatePrinter,
} from "./types";

const updatePrinter = (data: UpdatePrinter) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdatePrinter>({
    method: "PUT",
    path: "/api/v1/printer/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getPrinterList = (data: GetPrinterList) => {
  type Response = ApiBase.Types.ApiResBase<
    PrinterItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetPrinterList>({
    method: "POST",
    path: "/api/v1/printer/list",
    data: data,
  });
};

const copyPrinter = (idx: number) => {
  return ApiBase.request<ApiBase.Types.ApiResBase<null>>({
    method: "POST",
    path: `/api/v1/printer/copy/${idx}`,
  });
};

const addPrinter = (data: AddPrinter) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddPrinter>({
    method: "POST",
    path: "/api/v1/printer/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getPrinter = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Printer>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/printer/${idx}`,
  });
};

const deletePrinter = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/printer/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/printer/update`
   *
   * 프린터 스펙을 수정합니다.
   *
   * Update printer spec.
   */
  update: updatePrinter,
  /**
   * `POST /api/v1/printer/list`
   *
   * 프린터 스펙 목록을 조회합니다.
   *
   * Retrieve a list of printer specs.
   */
  getList: getPrinterList,
  /**
   * `POST /api/v1/printer/copy/:idx`
   *
   * 프린터 스펙을 복제합니다.
   *
   * Copy a printer spec.
   */
  copy: copyPrinter,
  /**
   * `POST /api/v1/printer/add`
   *
   * 프린터 스펙을 추가합니다.
   *
   * Add a printer spec.
   */
  add: addPrinter,
  /**
   * `GET /api/v1/printer/:idx`
   *
   * 프린터 스펙을 조회합니다.
   *
   * Retrieve a printer spec.
   */
  get: getPrinter,
  /**
   * `DELETE /api/v1/printer/:idx`
   *
   * 프린터 스펙을 삭제합니다.
   *
   * Delete a printer spec.
   */
  delete: deletePrinter,
};
