import * as ApiBase from "@metamorp/api-base";
import { ApiRequestStatus } from "./type";
import { DateRange } from "../common/type";

const getApiRequestStatus = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<ApiRequestStatus>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/api/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/api/state`
   *
   * @authorization-required
   *
   * Retrieve Api Request status data.
   */
  get: getApiRequestStatus,
};
