import {useEffect, useState} from "react";
import BaseChartCard from "@/pages/Users/Dashboard/chart/BaseChartCard";
import {UDActionStateResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
    res: UDActionStateResVo,
    name: string,
};

const Chart = (data: CardProps) => {

    const [chartOptions, setChartOptions] = useState<any | null>(null);

    useEffect(() => {
        setChartOptions({
            series: [
                {
                    name: "메타몰프 평균",
                    data: data.res.metaAvgList,
                },
                {
                    name: data.name,
                    data: data.res.userReqCntList,
                },
            ],
            chart: {
                toolbar: {
                    show: false,
                },
                type: "bar",
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight:'600',
                    height: 290,
            },
            colors: [ "var(--primary-color)", "rgb(227, 84, 212)", "rgb(255, 142, 111)", "rgb(255, 93, 159)"],
                plotOptions: {
                bar: {
                    columnWidth: "30%",
                        borderRadiusApplication: "around",
                        borderRadiusWhenStacked: "all",
                        borderRadius: 3,
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                    position: "top",
            },
            grid: {
                borderColor: "rgba(0,0,0,0.1)",
                    strokeDashArray: 3,
                    xaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                categories: data.res.actionNameList,
            },
            yaxis: {
                tickAmount: 4,
            },
        });
    }, [data.res]);

    return (
        <BaseChartCard
            toolTipId={"action"}
            title="기능사용현황"
            description="메타몰프 평균대비 사용자의 기능사용현황입니다."
            chartOptions={chartOptions}
         />
    );
};

export default Chart;
