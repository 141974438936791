import { ReactNode } from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { formatNumber } from "./utils";
import noImg from "../../assets/images/no-image.jpg";

type TopConnectedProps = {
  data: Api.Response.TopConnectedUser[] | null;
  isHeader?: boolean;
  isFullWidth?: boolean;
  children?: ReactNode;
};

const getColorClass = (index: number) => {
  const colorClasses = [
    "primary",
    "primary1",
    "primary2",
    "primary3",
    "success",
  ];
  return colorClasses[index % colorClasses.length];
};

const TopConnected = ({
  data,
  isHeader,
  children,
  isFullWidth,
}: TopConnectedProps) => {

  const navigate = useNavigate();
  const moveToDashBoard = (userIdx: number) => {
    navigate(`/users/dashboard/${userIdx}${window.location.search}`);
  };

  return (
    <ChartBase
      title="상위접속자"
      tooltipId="topConnected"
      tooltipContent="최근30일동안 접속한 상위 5명 사용자입니다."
      redirectUrl="/top-user-ranking"
      minHeight="418px"
      isHeader={isHeader}
    >
      {children}
      <div>
        <div className="progress-stacked progress-sm mb-2 gap-1">
          {data?.map((user, index) => (
            <div
              key={index}
              className={`progress-bar bg-${getColorClass(index)}`}
              role="progressbar"
              style={{ width: `${user.percentageCurrentRate}%` }}
              aria-valuenow={user.percentageCurrentRate}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          ))}
        </div>
      </div>
      <div className="table-responsive custom-pd">
        <table
          className="table text-nowrap text-center"
          style={{ padding: "8px !important" }}
        >
          {isFullWidth ? (
            <>
              <thead>
              <tr>
                <th>순위</th>
                <th>프로필</th>
                <th>국가/ID</th>
                <th>접속일수</th>
                <th>접속률</th>
                <th>전월대비</th>
                <th>대시보드</th>
              </tr>
              </thead>
              <tbody className="align-middle text-center">
                {data?.map((user, index) => (
                    <tr key={user.id}>
                      <td>{index + 1}</td>
                      <td
                          style={{
                            padding: "0px",
                          }}
                      >
                      <span className="avatar avatar-md align-middle">
                        <img src={user.imgPath || noImg} alt="User Photo"/>
                      </span>
                      </td>
                      <td style={{paddingLeft: "0rem"}}>
                        {user.country}
                        <p className="mb-0">{user.id}</p>
                      </td>
                      <td>{formatNumber(user.connectedCnt)}</td>
                      <td>{user.percentageCurrent}%</td>
                      <td>
                      <span
                          className={`badge bg-${getColorClass(index)}`}
                          style={{width: "4.375rem"}}
                      >
                        {user.percentageChange}%{" "}
                        {user.percentageChange > 0 ? (
                            <i className="ri-arrow-right-up-line"></i>
                        ) : (
                            <i className="ri-arrow-right-down-line"></i>
                        )}
                      </span>
                      </td>
                      <td>
                        <Button
                            type="button"
                            size="sm"
                            color="dark"
                            onClick={() => moveToDashBoard(user.userIdx)}
                        >
                          보기
                        </Button>
                      </td>
                    </tr>
                ))}
              </tbody>
            </>
          ) : (
              <>
                <thead>
                <tr>
                  <th>프로필</th>
                  <th>ID</th>
                  <th>접속일수</th>
                  <th>전월대비</th>
                </tr>
                </thead>
                <tbody className="align-middle text-center">
                {data?.map((user, index) => (
                    <tr key={user.id}>
                      <td
                          style={{
                            padding: "0px",
                          }}
                    >
                      <span className="avatar avatar-md align-middle">
                        <img src={user.imgPath || noImg} alt="User Photo" />
                      </span>
                    </td>
                    <td style={{ paddingLeft: "0rem" }}>
                      <div
                        className="col-8 col-md-6 text-start"
                        style={{ width: "10rem" }}
                      >
                        <p className="mb-0">
                          <span className="fw-bold text-dark">
                            {user.country}
                          </span>{" "}
                        </p>
                        <span className="text-muted fs-14 fw-bold">
                          {user.id}
                        </span>
                      </div>
                    </td>
                    <td>{formatNumber(user.connectedCnt)}</td>
                    <td>
                      <span
                        className={`badge bg-${getColorClass(index)}`}
                        style={{ width: "4.375rem" }}
                      >
                        {user.percentageChange}%{" "}
                        {user.percentageChange > 0 ? (
                          <i className="ri-arrow-right-up-line"></i>
                        ) : (
                          <i className="ri-arrow-right-down-line"></i>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>
      </div>
    </ChartBase>
  );
};

export default TopConnected;
