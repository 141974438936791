import * as ApiBase from "@metamorp/api-base";
import {
  AddApplication,
  Application,
  ApplicationItem,
  GetApplicationList,
  UpdateApplication,
} from "./types";

const updateApplication = (data: UpdateApplication) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdateApplication>({
    method: "PUT",
    path: "/api/v1/app/type/update",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getApplicationList = (data: GetApplicationList) => {
  type Response = ApiBase.Types.ApiResBase<
    ApplicationItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetApplicationList>({
    method: "POST",
    path: "/api/v1/app/type/list",
    data: data,
  });
};

const addApplication = (data: AddApplication) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddApplication>({
    method: "POST",
    path: "/api/v1/app/type/add",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getApplication = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<Application>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/app/type/${idx}`,
  });
};

const deleteApplication = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/app/type/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/app/type/update`
   *
   * 어플리케이션 타입을 수정합니다.
   *
   * Update the application type.
   */
  update: updateApplication,
  /**
   * `POST /api/v1/app/type/list`
   *
   * 어플리케이션 타입 목록을 조회합니다.
   *
   * Retrieve a list of application types.
   */
  getList: getApplicationList,
  /**
   * `POST /api/v1/app/type/add`
   *
   * 어플리케이션 타입을 추가합니다.
   *
   * Add an application type.
   */
  add: addApplication,
  /**
   * `GET /api/v1/app/type/:idx`
   *
   * 어플리케이션을 조회합니다.
   *
   * Retrieve an application.
   */
  get: getApplication,
  /**
   * `DELETE /api/v1/app/type/:idx`
   *
   * 어플리케이션을 삭제합니다.
   *
   * Delete an application.
   */
  delete: deleteApplication,
};
