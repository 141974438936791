import * as ApiBase from "@metamorp/api-base";
import {
  AddPrintingProfile, AdminProfileToUserProfileReq,
  GetPrintingProfileList,
  Migrate,
  MigrationResultData,
  PrintingProfile,
  PrintingProfileItem,
  UpdatePrintingProfile,
} from "./types";

const updatePrintingProfile = (data: UpdatePrintingProfile) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, UpdatePrintingProfile>({
    method: "PUT",
    path: "/api/v1/printing/profile/update",
    data: data,
  });
};

const getPrintingProfileList = (data: GetPrintingProfileList) => {
  type Response = ApiBase.Types.ApiResBase<
    PrintingProfileItem[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response, GetPrintingProfileList>({
    method: "POST",
    path: "/api/v1/printing/profile/list",
    data: data,
  });
};

const copyPrintingProfile = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/printing/profile/copy/${idx}`,
  });
};

const copyPrintingProfileForMigration = (data: Migrate) => {
  type Response = ApiBase.Types.ApiResBase<MigrationResultData>;
  return ApiBase.request<Response, Migrate>({
    method: "POST",
    path: "/api/v1/printing/profile/copy/profile",
    data: data,
  });
};

const addPrintingProfile = (data: AddPrintingProfile) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response, AddPrintingProfile>({
    method: "POST",
    path: "/api/v1/printing/profile/add",
    data: data,
  });
};

const getPrintingProfile = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<PrintingProfile>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/printing/profile/${idx}`,
  });
};

const deletePrintingProfile = (idx: number) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/printing/profile/${idx}`,
  });
};

const adminProfileToUserProfile = (data: AdminProfileToUserProfileReq) => {
  type Response = ApiBase.Types.ApiResBase<null>;
  return ApiBase.request<Response>({
    method: "PUT",
    path: "/api/v1/printing/profile/to/user/profile",
    data: data,
  });
};

export default {
  /**
   * `PUT /api/v1/printing/profile/update`
   *
   * 프린팅 프로파일을 수정합니다.
   *
   * Update printing profile.
   */
  update: updatePrintingProfile,
  /**
   * `POST /api/v1/printing/profile/list`
   *
   * 프린팅 프로파일 목록을 조회합니다.
   *
   * Retrieve a list of printing profiles.
   */
  getList: getPrintingProfileList,
  /**
   * `POST /api/v1/printing/profile/copy/:idx`
   *
   * 프린팅 프로파일을 복제합니다.
   *
   * Copy a printing profile.
   */
  copy: copyPrintingProfile,
  /**
   * `POST /api/v1/printing/profile/copy/profile`
   *
   * 프린팅 프로파일 마이그레이션을 요청합니다.
   *
   * Request a printing profile migration.
   */
  copyForMigration: copyPrintingProfileForMigration,
  /**
   * `POST /api/v1/printing/profile/add`
   *
   * 프린팅 프로파일을 추가합니다.
   *
   * Add printing profile.
   */
  add: addPrintingProfile,
  /**
   * `GET /api/v1/printing/profile/:idx`
   *
   * 프린팅 프로파일을 조회합니다.
   *
   * Retrieve a printing profile.
   */
  get: getPrintingProfile,
  /**
   * `DELETE /api/v1/printing/profile/:idx`
   *
   * 프린팅 프로파일을 삭제합니다.
   *
   * Delete a printing profile.
   */
  delete: deletePrintingProfile,

  /**
   * `PUT /api/v1/printing/profile/to/user/profile`
   *
   * 관리자프로파일 > 사용자프로파일전환
   *
   * Admin profile to user profile
   *
   */
  toUserProfile : adminProfileToUserProfile
};
