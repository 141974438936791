import * as ApiBase from "@metamorp/api-base";
import { apiRequest } from "@/utils/api/common/apiRequest";

import {
  UDActionStateResVo,
  UDConnectedDaysResVo,
  UDConnectionStateResVo,
  UDConnectionTimeResVo,
  UDDefaultReqVo,
  UDFunctionUseResVo,
  UDHistoryResVo,
  UDIncreaseCostResVo,
  UDRankStateResVo,
  UDRetentionResVo,
  UDTopCardResVo,
  UDUseCntResVo,
} from "./types";

export default {
  /**
   * 상단공통카드정보조회
   * @param idx
   */
  fetchTopCardData: (idx: string | undefined) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDTopCardResVo>>({
      method: "GET",
      path: `/api/v1/user/dashboard/top/card/${idx}`,
    });
  },
  /**
   * 비용카드정보조회
   * @param data
   */
  fetchIncreaseCostData: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDIncreaseCostResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/increase/cost",
      data: data,
    });
  },
  /**
   * 접속일수조회
   * @param data
   */
  fetchConnectedDaysData: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDConnectedDaysResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/connected/days",
      data: data,
    });
  },
  /**
   * 접속시간조회
   * @param data
   */
  fetchConnectionTimeData: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDConnectionTimeResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/connection/time",
      data: data,
    });
  },
  /**
   * AI사용횟수조회
   * @param data
   */
  fetchAIUseCntData: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDUseCntResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/use/ai/count",
      data: data,
    });
  },
  /**
   * API사용횟수조회
   * @param data
   */
  fetchAPIUseCntData: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDUseCntResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/use/api/count",
      data: data,
    });
  },
  /**
   * 리텐션조회
   * @param data
   */
  fetchRetentionData: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDRetentionResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/retention",
      data: data,
    });
  },
  /**
   * 접속현황조회
   * @param data
   */
  fetchConnectionState: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDConnectionStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/connection/count",
      data: data,
    });
  },
  /**
   * 기능사용현황조회
   * @param data
   */
  fetchActionState: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDActionStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/function/use/status",
      data: data,
    });
  },
  /**
   * 소재사용순위조회
   * @param data
   */
  fetchMaterialRank: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDRankStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/material/rank",
      data: data,
    });
  },
  /**
   * 프린터사용순위조회
   * @param data
   */
  fetchPrinterRank: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDRankStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/printer/rank",
      data: data,
    });
  },
  /**
   * 프린팅프로파일사용순위조회
   * @param data
   */
  fetchPrintingRank: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDRankStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/printing/rank",
      data: data,
    });
  },
  /**
   * 서포트프로파일사용순위조회
   * @param data
   */
  fetchSupportRank: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDRankStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/support/rank",
      data: data,
    });
  },
  /**
   * 모델사용순위조회
   * @param data
   */
  fetchModelRank: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDRankStateResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/model/rank",
      data: data,
    });
  },
  /**
   * 기능별사용빈도조회
   * @param data
   */
  fetchFunctionUseState: (data: UDDefaultReqVo) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDFunctionUseResVo>>({
      method: "POST",
      path: "/api/v1/user/dashboard/action/status",
      data: data,
    });
  },
  /**
   * 사용기록조회
   * @param idx
   */
  fetchUserHistory: (idx: string | undefined) => {
    return apiRequest<ApiBase.Types.ApiResBase<UDHistoryResVo[]>>({
      method: "GET",
      path: `/api/v1/user/dashboard/log/list/${idx}`,
    });
  },
};
