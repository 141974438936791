import * as ApiBase from "@metamorp/api-base";
import { RequestingRegion } from "./type";
import { DateRange } from "../common/type";

const getRequestingRegions = (data: DateRange) => {
  type Response = ApiBase.Types.ApiResBase<
    RequestingRegion[],
    ApiBase.Types.PagingRes
  >;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/req/korea/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/req/korea/state`
   *
   * @authorization-required
   *
   * Retrieve Requesting Regions data.
   */
  getList: getRequestingRegions,
};
