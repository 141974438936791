import {useEffect, useState} from "react";
import BaseChartCard from "@/pages/Users/Dashboard/chart/BaseChartCard";
import {UDConnectionStateResVo} from "@/utils/api/features/User/Dashboard/types";

type CardProps = {
    res: UDConnectionStateResVo,
    name: string,
};

const ConnectChart = (data: CardProps) => {

    const [chartOptions, setChartOptions] = useState<any | null>(null);

    useEffect(() => {
        setChartOptions({
            series: [
                {
                    type: "area",
                    name: "메타몰프 평균",
                    data: data.res.metaAvgList,
                },
                {
                    type: "line",
                    name: data.name,
                    data: data.res.userReqCntList,
                },
            ],
            chart: {
                type: "line",
                height: 290,
                toolbar: {
                    show: false,
                },
                dropShadow: {
                    enabled: true,
                    top: 7,
                    left: 0,
                    blur: 1,
                    opacity: 0.05,
                },
            },
            grid: {
                borderColor: "#f1f1f1",
                strokeDashArray: 3,
            },
            fill: {
                type: ["gradient", "solid"],
                gradient: {
                    opacityFrom: 0.23,
                    opacityTo: 0.23,
                    shadeIntensity: 0.3,
                },
            },
            stroke: {
                width: [1.5, 2],
                dashArray: [5, 0],
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: "top",
            },
            yaxis: {
                show: true,
                labels: {
                    show: true,
                },
            },
            xaxis: {
                type: "datetime",
                categories: data.res.dateList,
                labels: {
                    formatter: function (value: string) {
                        const date = new Date(value);
                        const year = String(date.getFullYear()).slice(-2); // 'yy' 형식의 년도
                        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 'MM' 형식의 월
                        const day = ('0' + date.getDate()).slice(-2); // 'dd' 형식의 일
                        return `${year}.${month}.${day}`;
                    },
                },
                axisBorder: {
                    show: false,
                    color: "rgba(119, 119, 142, 0.05)",
                },

            },
        });
    }, [data.res]);

    return (
        <BaseChartCard
            toolTipId={"connectionState"}
            title="접속현황"
            description="메타몰프 평균대비 사용자의 접속현황치입니다."
            chartOptions={chartOptions}
         />
    );
};

export default ConnectChart;
